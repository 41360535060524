import { ViewEditDeleteActionComponent } from "../../Components/HR & Student Placement/HR Student Placement/ActionComponent";
import { getHrStudentTableData } from "../../functions/admin/HR Management/hr_management";

export const hrStudentPlacement = {
    headers: [
        {
            name: "Date",
            apiKey: "date",
            headerClass: "w-[120px] text-center",
            className: " text-center",
        },
        {
            name: "Company Name",
            apiKey: "company",
            headerClass: "w-[150px] text-center",
            className: " text-center ",
        },
        {
            name: "Job Role",
            apiKey: "position",
            headerClass: "w-[150px] text-center",
            className: " text-center",
        },
        {
            name: "Location",
            apiKey: "location",
            headerClass: "w-[100px] text-center",
            className: "text-center",
        },
        {
            name: "Technology",
            apiKey: "technology",
            headerClass: "w-[160px] text-center",
            className: "text-center",
        },
        
        {
            name: "Action",
            apiKey: "Courses",
            isComponent: true,
            Comp: ViewEditDeleteActionComponent, //Will render the component in Table,
            headerClass: "w-[250px] text-center",
            className: "text-center",
        },
    ],
     BackendFunctionCall: getHrStudentTableData,
    // bodyData: [
    //     {
    //         id: 1,
    //         date: "03 Dec 2022",
    //         company_name: "Accenture",
    //         job_role: "SEO Analyst",
    //         location: "Chennai",
    //         technology: "Digital Marketing",
    //     },
    //     {
    //         id: 2,
    //         date: "03 Dec 2022",
    //         company_name: "Amazon",
    //         job_role: "Graphic Designer",
    //         location: "Bangalore",
    //         technology: "Graphic Design",
    //     },
    //     {
    //         id: 3,
    //         date: "03 Dec 2022",
    //         company_name: "Amazon",
    //         job_role: "Software Tester",
    //         location: "Mumbai",
    //         technology: "Software Tester",
    //     },
    //     {
    //         id: 4,
    //         date: "03 Dec 2022",
    //         company_name: "Amazon",
    //         job_role: "Software Tester",
    //         location: "Hyderabad",
    //         technology: "Software Tester",
    //     },
    //     {
    //         id: 5,
    //         date: "03 Dec 2022",
    //         company_name: "Accenture",
    //         job_role: "SEO Analyst",
    //         location: "Chennai",
    //         technology: "Digital Marketing",
    //     },
    //     {
    //         id: 6,
    //         date: "03 Dec 2022",
    //         company_name: "Accenture",
    //         job_role: "SEO Analyst",
    //         location: "Chennai",
    //         technology: "Digital Marketing",
    //     },
    //     {
    //         id: 7,
    //         date: "03 Dec 2022",
    //         company_name: "Accenture",
    //         job_role: "SEO Analyst",
    //         location: "Chennai",
    //         technology: "Digital Marketing",
    //     },
    //     {
    //         id: 8,
    //         date: "03 Dec 2022",
    //         company_name: "Accenture",
    //         job_role: "SEO Analyst",
    //         location: "Chennai",
    //         technology: "Digital Marketing",
    //     },
    //     {
    //         id: 9,
    //         date: "03 Dec 2022",
    //         company_name: "Accenture",
    //         job_role: "SEO Analyst",
    //         location: "Chennai",
    //         technology: "Digital Marketing",
    //     },
    //     {
    //         id: 10,
    //         date: "03 Dec 2022",
    //         company_name: "Accenture",
    //         job_role: "SEO Analyst",
    //         location: "Chennai",
    //         technology: "Digital Marketing",
    //     },
    // ],
    filters: {
        dateFilters: [
            {
                name: "Date",
                apiKey: "date",
                isSearchNeeded: true,
                className: "w-[180px] ",
            },
        ],
        checkBox: [
            // {
            //     name: "Date",
            //     apiKey: "date",
            //     isSearchNeeded: true,
            //     className: "md:w-[90px] lg:w-[100px] xl:w-[90px] 2xl:w-[150px]",
            // },
            {
                name: "Company Name",
                apiKey: "company",
                isSearchNeeded: true,
                className: "md:w-[160px] lg:w-[160px] xl:w-[200px] 2xl:w-[150px]",
            },
            {
                name: "Location",
            apiKey: "location",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[120px] xl:w-[200px] 2xl:w-[150px]",
            },
            {
                name: "Technology",
            apiKey: "technology",
                isSearchNeeded: true,
                className: "md:w-[130px] lg:w-[130px] xl:w-[200px] 2xl:w-[150px]",
            }
        ]
    },
    ActionComponent: ViewEditDeleteActionComponent,
};
import { useState, useEffect, Fragment } from "react";
import { LeftNavbar } from "../../Components/LeftNavbar/LeftNavbar";
import { RightSideScreens } from "../RightSideScreens/RightSideScreens";
import { Header } from "../../Components/Header/Header";
import { useAppDispatch, useAppSelector } from "../..";
import { getLoggedUserDetails } from "../../functions/admin/User Mangement/userMangement";
import { setUserRoles } from "../../Redux/Reducers/userSelectedRoles";
import { ScaleLoader } from "react-spinners";
import { FetchData } from "../../functions/crud";
import { TableNames } from "../../functions/config/Tables";
import { setSignInData } from "../../Redux/Reducers/signInUserData";
import toast from "react-hot-toast";
import { syncAllDataToUAT } from "../../ProductionToUatDataFetching";

export const MainComponent = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { userDetails } = useAppSelector(({ login }: any) => login);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (window.navigator.onLine) {
        const menuAccessResponse: any = await getLoggedUserDetails(userDetails);
        dispatch(
          setUserRoles({
            selectedRoles: menuAccessResponse?.menuAccess,
          })
        );
        const { data: tableData, error }: any = await FetchData(
          TableNames.users
        ).eq("uid", userDetails);
        const {
          data: loginUserAccessRole,
          error: loginUserAccessRoleError,
        }: any = await FetchData(TableNames.roles, ["id", "role"]);

        if (loginUserAccessRoleError) throw loginUserAccessRoleError;

        const filteredTableData =
          loginUserAccessRole &&
          loginUserAccessRole?.filter((entry: any) =>
            tableData?.[0]?.roles_array?.includes(entry.id)
          );
        const mapFilteredTableData =
          filteredTableData &&
          filteredTableData?.map((data: any) => data?.role);
        dispatch(
          setSignInData({
            data: [
              {
                uid: userDetails,
                name:
                  tableData?.[0]?.first_name.concat(
                    " ",
                    tableData?.[0]?.last_name
                  ) || "",
                cities: tableData?.[0]?.cities,
                branches: tableData?.[0]?.branches,
                roles: mapFilteredTableData,
                user_type: tableData?.[0]?.user_type,
              },
            ],
          })
        );
        // syncAllDataToUAT()
      } else toast.error("Please Check your Internet Connection");
      setIsLoading(false);
    })();
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <ScaleLoader color="#065FEF" />
        </div>
      ) : (
        <div className="flex h-screen">
          <LeftNavbar />
          <div className="flex flex-col w-full bg-[#F9FCFF] relative overflow-auto snap-y snap-mandatory scrollBar scrollbar-hide scroll-smooth">
            <Header />
            <RightSideScreens />
          </div>
        </div>
      )}
    </Fragment>
  );
};

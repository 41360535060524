import { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createSearchParams } from "react-router-dom";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { dateFormat, onKeyDown } from "../../../Services/CommonFunctions";
import { Loader } from "../../Elements/Loader/Loader";
import { InputBox } from "../../Elements/InputBox";
import { DropDownInputBox } from "../../Elements/DropDownInputBox";
import { AddKeySkills } from "./AddKeySkills";
import { add_create_job } from "../../Formik/InitialValues";
import { AddCreatJobValidation } from "../../Formik/FormikValidation";
import { getHrStudentPreloadData, updateJob,createNewJob,getHrStudentTableData,getjobId,viewApplicants,getAllCompaniesList } from "../../../functions/admin/HR Management/hr_management";
import { useAppSelector } from "../../..";
import { useSharedLogic, FormikCommonFunc } from "../../CommonFunctions/CommonFunc";
import { SubmitButton } from "../../Elements/SubmitButton";
import './customQuillStyles.css'

export const AddNewJobs = ({ id, mode }: any) => {
  const { navigate, routeNameForHome, routeNameForSubSection }: any = useSharedLogic();
  const { userDetails } = useAppSelector(({ login }: any) => login);
    const [checked, setChecked] = useState<any>("Active");
    const [isLoading, setIsLoading] = useState(false)
    const [roomsCount, setRoomsCount] = useState([] as any)
    const [filteredOwnership, setFilteredOwnership] = useState("Open" as any);
    const [filteredCity, setFilteredCity] = useState(null as any);
    const [cityNames, setCityNames] = useState([] as any)
    const [technologyNames, setTechnologyNames] = useState([] as any)
    const [cityData, setCityData] = useState([] as any)
    const [technologyData, setTechnologyData] = useState([] as any)
    const [follow_up_date, setFollow_up_date] = useState("");
    const [companyData, setCompanyData] = useState([] as any)
    const [companyNames, setCompanyNames] = useState([] as any);
    const [filteredCompany, setFilteredCompany] = useState(null as any);
    const [locationData, setLocationData] = useState([] as any)
    const [locationNames, setLocationNames] = useState([] as any);
    const [filteredLocation, setFilteredLocation] = useState(null as any);
    const [rolesData, setRolesData] = useState([] as any)
    const [rolesNames, setRolesNames] = useState([] as any);
    const [filteredRoles, setFilteredRoles] = useState(null as any);

    

    const ownershipType: string[] = ["Open", "Closed", "Opening On Hold"];
    const isId: any = id !== "new-jobs" && id;

    const modules = {
      toolbar: [
          [{ 'header': '1' }, { 'header': '2' }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline'],
          ['blockquote', 'code-block'],
          ['image'],
          [{ 'color': [] }, { 'background': [] }],
          ['clean'] // Remove formatting button
      ],
  };

    const formats = [
      'header',
      'list',
      'bullet',
      'bold',
      'italic',
      'underline',
      'color',
      'background',
      'image',
      'blockquote',
      'code-block'
    ];

  useEffect(() => {
    (async () => {
      await getAllCompanies();
    })();
  }, []);
  

  const getAllCompanies = async () => {
    const res: any = await getAllCompaniesList()
    if(res?.company?.length > 0) {
      const mapCity = res?.company && res?.company.map((company: any) => company.company);
      setCompanyNames(mapCity);
      setCompanyData(res?.company)
    }
    if(res?.location?.length > 0) {
      const mapCity = res?.location && res?.location.map((location: any) => location.location);
      setLocationNames(mapCity);
      setLocationData(res?.location)
    }
    if(res?.roles?.length > 0) {
      const mapCity = res?.roles && res?.roles.map((roles: any) => roles.position);
      setRolesNames(mapCity);
      setRolesData(res?.roles)
    }
  }

    useEffect(() => {
        (async () => {
          const technologyResponse: any = await getHrStudentPreloadData();
          setTechnologyData(technologyResponse);
          if (technologyResponse) {
            const mapCity =
              technologyResponse &&
              technologyResponse.map((technology: any) => technology.technology);
            setTechnologyNames(mapCity);
          }
          if (mode && mode === "edit") {
              const editResponse = await getjobId(isId);
              const { company,location,position,technology,about_company,key_skills,job_description,eligibility,last_date_to_apply, salary,number_of_openings, status }: any = editResponse;
              const mapCityForId:any = technologyResponse &&
                  technologyResponse.filter((data: any) => data.id === technology);
              if (editResponse) {
                setValues(
                  {
                    ...values,
                    company,
                    location,
                    position,
                    technology,
                    about_company,
                    key_skills,
                    job_description,
                    number_of_openings,
                    last_date_to_apply,
                    eligibility,
                    salary,
                    status,
                  },
                  true
                );
                setChecked(status);
                setFilteredOwnership(status === "" ? "Open" : status);
                setFilteredCity(mapCityForId?.[0]["technology"]);
                setFilteredCompany(company);
                setFilteredLocation(location);
                setFilteredRoles(position);
                if(key_skills!==null){
                    var pushData: any = [];
                    for (let i = 0; i < key_skills.length - 1; i++) {
                        pushData.unshift([]);
                    }
                    setRoomsCount(pushData);
                }
              }
          }
        })()
    },[])
    
    const forNewBranch = async () => {
      setIsLoading(true);
      const {...rest} = values;
        const body: any = {
            ...rest
        }
        const response = await(
          id === "new-jobs"
            ? createNewJob(body)
            : updateJob(isId, body)
        );
        if (response) {
            navigate({
            pathname: `/${routeNameForHome}/${routeNameForSubSection}`,
            search: createSearchParams({ id: `${id === "new-jobs" ? "jobs-created-success" : "jobs-update-success"}` }).toString(),
            });
        }
        setIsLoading(false);
    }

    const { formik, values, errors, touched, handleBlur, handleSubmit, handleChange, setValues, setFieldValue, }: any = FormikCommonFunc(add_create_job, AddCreatJobValidation, forNewBranch);
    
    const addMoreRooms = () => {
        const add = [...roomsCount, []];
        setRoomsCount(add)
    }

    const deleteRooms = (i: number, value: any) => {
        const updatedArray = [...values.key_skills];
        const filterData: any = updatedArray.filter(
        (str: any) => str !== value
        );
        setFieldValue("key_skills", filterData);
        const deleteOne = [...roomsCount];
        deleteOne.splice(i, 1);
        setRoomsCount(deleteOne);
    }

        
    const CheckValue = (label: any) => {
        setChecked(label);
        setFieldValue("status", label);
    };

    const handleChangeForDropValue = (
        name: string,
        value: string,
        mapThrough?: string
    ) => {
        if (mapThrough) {
            setFilteredCity(value);
            const data =
            technologyData &&
            technologyData.filter((data: any) => data["technology"] === value);
            setFieldValue(name, data[0]['id']);
        } else {
           // setFilteredOwnership(value);
           setFilteredOwnership(value);
            setFieldValue(name, value);
        }
    };

    const handleChangeForCompanyDropValue = (
        name: string,
        value: string,
        mapThrough?: string
    ) => {      
        if (mapThrough) {
            setFilteredCompany(value);
            const data = companyData && companyData.filter((data: any) => data["company"] === value);
            setFieldValue(name, data[0]['company']);
        } else {
           // setFilteredOwnership(value);
           setFilteredOwnership(value);
          setFieldValue(name, value);
        }
    };

    const handleChangeForLocationDropValue = (
        name: string,
        value: string,
        mapThrough?: string
    ) => {
        if (mapThrough) {
            setFilteredLocation(value);
            const data = locationData && locationData.filter((data: any) => data["location"] === value);
            setFieldValue(name, data[0]['location']);
        } else {
          setFilteredOwnership(value);
          setFieldValue(name, value);
        }
    };

    const handleChangeForRolesDropValue = (
      name: string,
      value: string,
      mapThrough?: string
    ) => {
        if (mapThrough) {
            setFilteredRoles(value);
            if (!Array.isArray(rolesData)) {
                return;
            }
            const data = rolesData && rolesData.filter((data: any) => data["position"] === value);
            if (data.length > 0 && data[0] && data[0].id) {  // Ensure data[0] exists and has an 'id'
                setFieldValue(name, data[0]['position']);
            } else {
                console.error("No matching role found or role does not have an 'id'");
            }
        } else {
            setFilteredOwnership(value);
            setFieldValue(name, value);
        }
    };

    const handleChangeForRooms = (index: number, value: any) => {
        const updatedInputValues:any = [...values.key_skills];
        updatedInputValues[index] = value;
        setFieldValue("key_skills", updatedInputValues);
    }

    return (
      <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
        {isLoading ? (
          <Loader widthFix={"w-11/12"} />
        ) : (
          <>
            <CardLayoutTwo>
              <div className="text-left border-b-2 p-2">
                <p className="font-Roboto font-medium text-[24px] text-[#707070] my-3 ml-4">
                  {id === "new-branch" ? "Job Info" : "Job Info"}
                </p>
              </div>
              <div className="w-10/12 mx-auto  gap-12">
                <div className="w-full grid grid-cols-2 pt-3 gap-6">
                  <DropDownInputBox
                    label="Company Name:"
                    placeholder="Company Name"
                    classNameInput={"w-full"}
                    name={"company"}
                    value={values.company}
                    onBlur={handleBlur}
                    dataSetup={handleChangeForCompanyDropValue}
                    mapData={companyNames}
                    showData={filteredCompany}
                    setShowData={setFilteredCompany}
                    mapThrough={"company"}
                    formik={formik}
                    error={formik.errors?.company}
                    zIndex={"z-20"}
                  />
                   <DropDownInputBox
                    label="Job Location:"
                    placeholder="Job Location"
                    classNameInput={"flex flex-col w-full"}
                    name={"location"}
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    dataSetup={handleChangeForLocationDropValue}
                    mapData={locationNames}
                    showData={filteredLocation}
                    setShowData={setFilteredLocation}
                    mapThrough={"location"}
                    error={errors?.location}
                    formik={formik}
                    zIndex={"z-20"}
                  />
                </div>
                <div className="w-full grid grid-cols-2 pt-3 gap-6">
                  <DropDownInputBox
                    label="Job Role:"
                    placeholder="Job Role"
                    classNameInput={"flex flex-col w-full"}
                    name={"position"}
                    value={values.position}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    dataSetup={handleChangeForRolesDropValue}
                    mapData={rolesNames}
                    showData={filteredRoles}
                    setShowData={setFilteredRoles}
                    mapThrough={"roles"}
                    formik={formik}
                    error={errors?.position}
                  />
                  <DropDownInputBox
                    label="Technology:"
                    placeholder="Technology"
                    classNameInput={"w-full"}
                    name={"technology"}
                    value={values.technology}
                    onBlur={handleBlur}
                    dataSetup={handleChangeForDropValue}
                    mapData={technologyNames}
                    showData={filteredCity}
                    setShowData={setFilteredCity}
                    mapThrough={"technology"}
                    formik={formik}
                    error={formik.errors?.technology}
                  />
                </div>
                <div className="w-full pt-10">
                <AddKeySkills
                  addMoreRooms={addMoreRooms}
                  roomsCount={roomsCount}
                  deleteRooms={deleteRooms}
                  formik={formik}
                  name={"key_skills"}
                  dataSetup={handleChangeForRooms}
                />
                </div>
        <div className="space-y-0.5 flex flex-col w-full pt-3 relative">
          <label className="2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500] text-[#707070]">
              About Company <span></span>
          </label>
          <ReactQuill
            theme="snow"
            style={{height : "5px", marginBottom : "40px"}}
            value={values.about_company}
            onChange={(content) => setFieldValue("about_company", content)}
            placeholder="About Company"
            formats={formats}
            modules={modules}
            readOnly={mode === "view"}
            className="min-h-[200px] px-2 py-2 font-[500] rounded-[3px]"
          />
          {touched.about_company && errors.about_company && (
            <span className="text-red-500 absolute -bottom-6 right-0">
              {errors.about_company}
            </span>
          )}
        </div>
        <div className="space-y-0.5 flex flex-col w-full pt-3 relative">
          <label className="2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500] text-[#707070]">
          Job Description <span></span>
          </label>
          <ReactQuill
            theme="snow"
            value={values.job_description}
            style={{height : "5px", marginBottom : "40px",}}
            onChange={(content) => setFieldValue("job_description", content)}
            placeholder="Job Description..."
            readOnly={mode === "view"}
            modules={modules}
            formats={formats}
            className="min-h-[200px] px-2 py-2  font-[500]  rounded-[3px]"
          />
          {touched.job_description && errors.job_description && (
          <span className="text-red-500 absolute -bottom-6 right-0">
            {errors.job_description}
          </span>
        )}
      </div>
      <div className="space-y-0.5 flex flex-col w-full pt-3 relative">
        <label className="2xl:text-[20px] lg:text-[18px] md:text-[14px] font-Roboto font-[500] text-[#707070]">
        Eligibility <span></span>
        </label>
        <ReactQuill
          theme="snow"
          modules={modules}
          style={{height : "5px", marginBottom : "40px",}}
          value={values.eligibility}
          onChange={(content) => setFieldValue('eligibility', content)} // Update the field value
          placeholder="Enter eligibility details..."
          className="min-h-[200px] px-2 py-2 font-[500] rounded-[3px]"
        />
        {touched.eligibility && errors.eligibility && (
        <span className="text-red-500 absolute -bottom-6 right-0">
          {errors.eligibility}
        </span>
        )}
        </div>
              <div className="w-full grid grid-cols-2 pt-3 gap-6">
                  <InputBox
                    label="Number of Openings:"
                    placeholder="Number of Openings"
                    classNameInput={"flex flex-col w-full"}
                    name={"number_of_openings"}
                    value={values.number_of_openings}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={'number'}
                    error={errors?.number_of_openings}
                    isTouched={touched?.number_of_openings}
                  />
                   <InputBox
                    label="Salary (Per Annum):"
                    placeholder="Salary"
                    classNameInput={"flex flex-col w-full"}
                    name={"salary"}
                    value={values.salary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                 
                </div>
                <div className="w-full grid grid-cols-2 pt-4 gap-6">                  
                  <InputBox
                    label="Last Date to Apply:"
                    type={'date'}
                    name={"last_date_to_apply"}
                    placeholder="01/01/2023"
                    value={values.last_date_to_apply}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    classNameInput={"2xl:text-[16px] cursor-pointer lg:text-[14px] md:text-[12px] font-Roboto rounded-[3px] px-2 py-2 w-full"}
                  />
                  <DropDownInputBox
                    label="Status:"
                    placeholder="Status"
                    classNameInput={"w-full"}
                    name={"status"}
                    value={values.status}
                    onBlur={handleBlur}
                    dataSetup={handleChangeForDropValue}
                    mapData={ownershipType}
                    showData={filteredOwnership}
                    setShowData={setFilteredOwnership}
                  />
                </div>
              </div>
              <div className="w-10/12 mx-auto pt-7 gap-12 text-center">
                <SubmitButton />
              </div>
            </CardLayoutTwo>
          </>
        )}
      </form>
    );
}


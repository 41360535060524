import React from 'react';
import { paginationConfig } from '../../../utils/config/elements';
import jumpToFirstPage from "../../../Assets/jumpToFirstPage.svg";
import jumpToLastPage from "../../../Assets/jumpToLastPage.svg";
import nextPage from "../../../Assets/nextPage.svg";
import previousPage from "../../../Assets/previousPage.svg";
// import doubleArrow from "../../../Assets/doubleArrow.svg";


interface PaginationProps {
    totalItems: number,
    currentPage: number,
    paginatorFunc: any,
    totalPages: number
}

const Pagination = ({ totalItems, currentPage, paginatorFunc, totalPages }: PaginationProps) => {
    const isNotFirstPage = currentPage !== 1
    const isNotLastPage = currentPage < totalPages;
    return (
        <div className='flex justify-end p-4 mt-4'>
            <div className='flex items-center space-x-4 text-[16px] text-[#686868] font-medium mr-6'>
                <p className='text-[16px] text-[#686868] font-medium'>{totalItems} Items</p>
                <img className='cursor-pointer ' src={jumpToFirstPage} alt='firstPagePaginatorIcon' onClick={() => isNotFirstPage && paginatorFunc(paginationConfig.firstPage)} />
                <img className='cursor-pointer' src={previousPage} alt='decrementPagePaginatorIcon' onClick={() => isNotFirstPage && paginatorFunc(paginationConfig.previousPage)} />
                <p>{currentPage} of {totalPages}</p>
                <img className='cursor-pointer' src={nextPage} alt='incrementPagePaginatorIcon' onClick={() => isNotLastPage && paginatorFunc(paginationConfig.nextPage)} />
                <img className='cursor-pointer' src={jumpToLastPage} alt='lastPagePaginatorIcon' onClick={() => isNotLastPage && paginatorFunc(paginationConfig.lastPage)} />
            </div>  
        </div>
    )
}

export default Pagination
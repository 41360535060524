import { map } from "lodash";
import { useState, useEffect } from "react";
import { getjobId, getHrStudentPreloadData } from "../../../functions/admin/HR Management/hr_management";
import { CardLayoutTwo } from "../../Elements/Cards/CardLayoutTwo";
import { InformationViewCard } from "../../Elements/InformationViewCard/InformationViewCard";
import { dateFormat } from "../../../Services/CommonFunctions";
import { Loader } from "../../Elements/Loader/Loader";
import { InformationJobViewCard } from "./InformationJobViewCard";
import parse from 'html-react-parser';

export const ViewJobsInfo = ({id}:any) => {
  const [user, setUser] = useState<any>({});
  const [technology,setTechnology] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);


        const buildJSON = [
          {
            left: [
              {
                head: "Company Name",
                body: user && user?.company,
              },
              {
                head: "Job Role",
                body: user && user?.position,
              },
            
              {
                head: "Number Of Openings",
                body: user && user?.number_of_openings,
              },
               {
                head: "Last Date to Apply",
                body: user && dateFormat(user?.last_date_to_apply, "DD MMM YYYY"),
              },
        
              
            ],
            right: [
                {
                    head: "Location",
                    body: user && user?.location,
                  },
                  {
                    head: "Technology",
                    body: technology && technology,
                  },
               
                  {
                    head: "Salary",
                    body: user && user?.salary,
                  },
               
              {
                head: "Status",
                body: user && user?.status !== "" ? user?.status : "Open" ,
              },
            
            ],
            center:[
                {
                    head: "Key Skills",
                    body: user && user?.key_skills?.join(', '),
                  },
                {
                    head: "About Company",
                    // body: user && user?.about_company,
                    body: user && typeof user?.about_company === 'string' ? parse(user?.about_company) : 'No description available',
                  },
                {
                    head: "Job Description",
                    // body: user && user?.job_description,
                    body: user && typeof user?.job_description === 'string' ? parse(user?.job_description) : 'No description available',
                  },
                {
                    head: "Eligibility",
                    // body: user && user?.eligibility,
                    body: user && typeof user?.eligibility === 'string' ? parse(user?.eligibility) : 'No description available',
                  },
            ]
            
          },
        ];
    
        useEffect(() => {
            (async () => {
            setIsLoading(true)
            const userData: any = await getjobId(id);
            const technologyResponse: any = await getHrStudentPreloadData();
        //  console.log("Tech Data", userData);
            const mapCityForId:any = technologyResponse &&
            technologyResponse.filter((data: any) => data.id === userData?.technology);
               
                setTechnology(mapCityForId?.[0]["technology"]);
            setUser(userData);
            setIsLoading(false)
          })();
        }, []);

    return (
      <>
        {isLoading ? (
          <Loader widthFix={"w-9/12"} />
        ) : (
          <CardLayoutTwo>
            <InformationJobViewCard
              cardHeading={"Jobs Information"}
              values={user}
              buildJSON={buildJSON}
            />
          </CardLayoutTwo>
        )}
      </>
    );
}
import React from 'react'

export const PaymentTotalsForPendingPayment = ({ bodyData }: any) => {
  console.log(bodyData, "bodyData");

  const sumKeyTotal = bodyData?.reduce((accumulator: any, currentValue: any) => {
    return Number(accumulator) + Number(currentValue?.total_fee);
  }, 0);

  const sumKeyTotalPaid = bodyData?.reduce((accumulator:any, currentValue:any) => {
    return Number(accumulator) + Number(currentValue?.total_paid);
  }, 0);

  const sumKeyBalance = bodyData?.reduce((accumulator:any, currentValue:any) => {
    return Number(accumulator) + Number(currentValue?.balance_due);
  }, 0);

  const mappingData = [{
    name: "Total Fee",
    value: sumKeyTotal || 0
  }, {
    name: "Total Paid",
    value: sumKeyTotalPaid || 0
  }, {
    name: "Balance Due",
    value: sumKeyBalance || 0
  }]

  return (
    <div className="w-11/12 mx-auto flex items-end justify-end gap-4">
      {mappingData?.map((keyData: any, index: number) => (
        <div key={index} className="flex gap-2">
          <h3 className="font-Roboto font-[500] text-[14px] 2xl:text-[15px]">
            {keyData?.name}:
          </h3>
          <p className="mr-2 text-[14px] 2xl:text-[15px]">
            {Math.round(Number(keyData?.value))}
          </p>
          {index === 2 ? (
            <span className="h-8"></span>
          ) : (
            <span className="border-l-2 border-black h-8 ml-2"></span>
          )}
        </div>
      ))}
    </div>
  );
}

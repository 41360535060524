import React from 'react';
import { BatchDropdown } from "./BatchDropdown";
import { updateStudentTraining } from '../../../functions/admin/Batches/Batches';

export const StudentData = ({id, mode, formik, studentNameDetails, handleStudentsData}:any) => {
    const Added_Student_Table_Header = [ "Student Name", "Student Phone Number", "Total Fee", "Amount Paid", "Balance Due", "Hours Attended", "Student Status"];
    const cellStyle = (text?: any) => `text-[#686868] text-[14px] font-Roboto font-[400] ${text ? "w-[7%]" : "w-[10%]"} text-center`;
    const Student_Status = ["Allocated", "Completed"];

    const removeRow = async (i: number, trainingId: number) => {
        if (mode === "edit") {
        const response = await updateStudentTraining(
            {
                status: "Hold",
                batch_id: id,
            },
            trainingId
        )}
        const newData = [...formik.values.add_student];
        newData.splice(i, 1);
        formik.setFieldValue("add_student", newData);
        const listData = [...formik.values.selected_student_list];
        listData.splice(i, 1);
        formik.setFieldValue("selected_student_list", listData);
    };

    const handleStudentStatus = async(field: string, value: string | string[], trainerId?: any) => {
        if (trainerId && mode === "edit") {
        const trainingData: any = {
            status: value,
            batch_id: id,
        };
        const response = await updateStudentTraining(trainingData, trainerId);
        if (response) {
            formik.setFieldValue(field, value);
        }
        } else if (id === "new-batch") {
            formik.setFieldValue(field, value);
        }
    }

  return (
    <div className="space-y-6">
      <h1 className="text-[#000000] text-[23px] font-Roboto font-[500]">
        Student Details
      </h1>
      <div className="space-y-2">
        <BatchDropdown
          name="add_student"
          label="Add Student"
          showLabel={true}
          options={studentNameDetails}
          placeholder="Add Student"
          value={formik.values.add_student}
          onChange={handleStudentsData}
          onBlur={formik.handleBlur}
          useCheckbox={true}
          multiSelect={true}
        />
        <div className="rounded-[7px] shadow-[#00000029_0px_3px_8px] min-h-[120px] py-3">
          <div className="bg-[#EFF7FF] flex items-center justify-between px-10 py-1">
            {Added_Student_Table_Header.map((head:any) => (
              <p
                className={`text-[#686868] text-[14px] font-Roboto font-[500] ${
                  head === "Student Status"
                    ? "w-[15%]"
                    : head === "Hours Attended"
                    ? "w-[7%]"
                    : "w-[10%]"
                } text-center`}
              >
                {head}
              </p>
            ))}
          </div>
          <div className="min-h-[60px] max-h-[300px] overflow-y-auto pt-2">
            {formik.values?.selected_student_list?.length > 0 &&
              formik.values?.selected_student_list.map(
                (body: any, i: number) => {
                  return (
                    <div
                      key={i}
                      className={`relative flex items-center justify-between px-10 py-1 ${
                        i % 2 === 0 ? "bg-white" : "bg-[#EFF7FF]"
                      }`}
                    >
                      <p className={`${cellStyle()} break-words`}>
                        {body?.student?.name}
                      </p>
                      <p className={`${cellStyle()}`}>
                        {body?.student?.phoneNumber}
                      </p>
                      <p className={`${cellStyle()}`}>
                        {body.total_fee > 0 && (
                          <span className="px-1">&#8377;</span>
                        )}
                        {body?.total_fee >= 0
                          ? body?.total_fee === 0
                            ? 0
                            : Number(body?.total_fee)?.toFixed(2)
                          : "-"}
                      </p>
                      <p className={`${cellStyle()}`}>
                        {body?.total_fee > 0 && (
                          <span className="px-1">&#8377;</span>
                        )}
                        {body?.total_fee >= 0
                          ? body?.total_fee === 0
                            ? 0
                            : (Number(body?.total_fee) - Number(body?.balance_due))?.toFixed(2)
                          : "-"}
                      </p>
                      <p className={`${cellStyle()}`}>
                        {body?.balance_due > 0 && (
                          <span className="px-1">&#8377;</span>
                        )}
                        {body?.balance_due >= 0
                          ? body?.balance_due === 0
                            ? 0
                            : Number(body.balance_due)?.toFixed(2)
                          : "-"}
                      </p>
                      <p className={`${cellStyle(0)}`}>
                        {body?.hours_attended !== null
                          ? body?.hours_attended
                          : 0}
                      </p>
                      <BatchDropdown
                        name={`selected_student_list[${i}]["status"]`}
                        label=""
                        showLabel={false}
                        options={Student_Status}
                        placeholder=""
                        value={formik.values?.selected_student_list?.[i]?.status}
                        onChange={handleStudentStatus}
                        onBlur={formik.handleBlur}
                        useCheckbox={true}
                        className={"w-[15%]"}
                        trainerId={body?.id}
                      />
                      <div
                        onClick={() => removeRow(i, body?.id)}
                        className="absolute -right-6 select-none bg-[#FF0000] px-2 w-4 h-4 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center"
                      >
                        {id === "new-batch" ? "-" : "x"}
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

import { toast } from "react-hot-toast";
import { AuthErrorMessages, isDBError } from "./Error/error";
import { SUPABASE_CLIENT } from "../Services/Supabase";
import { FetchData } from "./crud";

export const errorNotifier = (error: any) => {
    // console.log({ error });
    switch (true) {
        case error.name === "AuthApiError":
            AuthErrorHandler(error);
            break;
        case isDBError(error):
            DBErrorHandler(error);
            break;
        default:
            toast.error(error.details || error.message || error.name);
    }
    return false
}

export const DBErrorHandler = (error: any) => {
    let message;
    switch (error.code) {
        case "23505":
        message = error.details?.split("Key (")[1];
        
            message = message.split(")")[0] + " already exists";
            break;
        default:
            message = error.details||error.message
            break;
    }
    toast.error(message);

}


export const AuthErrorHandler = (error: any) => {
    let message;
    switch (error.message) {
        case "duplicate key value violates unique constraint \"users_email_partial_key\"":
        case "A user with this email address has already been registered":
            message = AuthErrorMessages.emailExists;
            break;

        default:
            message = error.message
            break;
    }
    toast.error(message);
}



// export const DuplicationErrorHandler = async (table: string, key: string, checker: string | undefined) => {
//     if (!checker) {
//         return {isExist: false}
//     }
//     let errorObj = {
//         message: `${checker} already exists.`
//     }
//     const {data} = await FetchData(table, [key]);
//     const arrayOfValues: any = data?.map((obj: any) => obj?.category_name)
//     return {isExist: arrayOfValues.includes(checker), isError: errorObj}
// }

// export const UpdateChecker = async (table: string, key: string, id: string, checker: string | undefined) => {
//     const changedData : any= await FetchData(table, [key]).eq("id", id)
//     console.log(changedData.data[0]?.category_name, checker)
//     if (changedData.data[0]?.category_name !== checker) {
//         return DuplicationErrorHandler(table, key, checker)
//     }
//     return true
// }

type Time = {
  hours: number;
  minutes: number;
  period: 'am' | 'pm';
};

const parseTime = (time: string): Time => {
  const [hoursStr, minutesStr, period] = time.split(/[:\s]/);
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  return { hours, minutes, period: period.toLowerCase() as 'am' | 'pm' };
};


export const calculateTimeDifference = (startTime: any, endTime: any): any => {

    // Split the times into hours and minutes
  // const [hour1, minute1] = startTime.split(':').map(Number);
  // const [hour2, minute2] = endTime.split(':').map(Number);

  // // Convert times to milliseconds since midnight
  // const time1Milliseconds = hour1 * 60 * 60 * 1000 + minute1 * 60 * 1000;
  // const time2Milliseconds = hour2 * 60 * 60 * 1000 + minute2 * 60 * 1000;

  // // Calculate the hour difference
  // let hourDifference = Math.abs((time2Milliseconds - time1Milliseconds) / (60 * 60 * 1000));

  // // Ensure the result is positive
  // if (hourDifference > 12) {
  //   hourDifference = 24 - hourDifference;
  // }

  const start:any = parseTimeData(startTime);
  const end:any = parseTimeData(endTime);
  const differenceInMilliseconds = end - start;
  const hours = differenceInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
  return hours.toFixed(0); // Return total hours with two decimal places
};

const parseTimeData = (timeStr:any) => {
  const [hours, minutes] = timeStr.split(":");
  const date = new Date();
  date.setHours(parseInt(hours), parseInt(minutes), 0, 0);
  return date;
};

// const parseTime = (timeString: string) => {
  
//   const [time, period] = timeString?.split(' ');
//   const [hours, minutes] = time?.split(':');
    
//   return {
//     hours: parseInt(hours),
//     minutes: parseInt(minutes),
//     period: period.toLowerCase()
//   };
// }

// export const calculateTimeDifference = (startTime: any, endTime: any): any => {

//     const start = parseTime(startTime);
//     const end = parseTime(endTime);
    
//   let minutesDifference = end.minutes - start.minutes;
//   let hoursDifference = end.hours - start.hours;

//   // Handle negative time difference
//   if (minutesDifference < 0) {
//     minutesDifference += 60;
//     hoursDifference--;
//   }

//   // Convert AM/PM to 24-hour format
//   if (start.period === 'pm' || end.period === "pm") {
//     hoursDifference += 12;
//   }

//   return hoursDifference + minutesDifference / 60;
// }

export const calculatePercentage = (progress_duration: any, total_duration: any) => {
  const progress_percent = ((Number(progress_duration) / Number(total_duration)) * 100)===0?"0":((Number(progress_duration) / Number(total_duration)) * 100).toFixed(1);
  return progress_percent;
}

// export const percentageBasedOnRange = (percentage:any) => {
//   if (percentage <= 10) return "0 - 10%";
//   else if (percentage <= 20) return "10 - 20%";
//   else if (percentage <= 30) return "20 - 30%";
//   else if (percentage <= 40) return "30 - 40%";
//   else if (percentage <= 50) return "40 - 50%";
//   else if (percentage <= 60) return "50 - 60%";
//   else if (percentage <= 70) return "60 - 70%";
//   else if (percentage <= 80) return "70 - 80%";
//   else if (percentage <= 90) return "80 - 90%";
//   else if (percentage <= 100) return "90 - 100%";
//   else return "Above 100%";
// };

export const percentageBasedOnRange = (percentage: any) => {
  const roundedPercentage = Math.floor(percentage / 10);
  return `${roundedPercentage * 10}-${Math.min(roundedPercentage * 10 + 10, 100)}%`;
};

export const discountBasedOnRange = (discount: any) => {
  const roundedDiscount = Math.floor(discount);
  return `${roundedDiscount}-${Math.min(roundedDiscount + 1, 15)}%`;
};

export function getTimeDifferenceInHours(startTime: string, endTime: string): number {
  const start = new Date(`1970-01-01T${startTime}:00`);
  const end = new Date(`1970-01-01T${endTime}:00`);

  const diffMs = end.getTime() - start.getTime();
  const diffHours = diffMs / (1000 * 60 * 60); // Convert milliseconds to hours

  return diffHours;
}

export const calculateDayDifference = (plannedDateOfCompletion: string, currentDate: string, scheduledDays: string[]) => {
  // Convert dates to Date objects
  const plannedDate:any = new Date(plannedDateOfCompletion);
  const currentDateObj:any = new Date(currentDate);

  // Calculate the initial difference in days
  let dayDifference = Math.ceil((currentDateObj - plannedDate) / (1000 * 60 * 60 * 24));

  // Adjust for scheduled days
  scheduledDays.forEach((dayOfWeek) => {
    let scheduledDate = new Date(plannedDate);
    while (scheduledDate <= currentDateObj) {
      if (scheduledDate.getDay() === getDayOfWeekIndex(dayOfWeek)) {
        dayDifference--;
      }
      scheduledDate.setDate(scheduledDate.getDate() + 1);
    }
  });

  return dayDifference;
};

const getDayOfWeekIndex = (dayOfWeek: string) => {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return daysOfWeek.indexOf(dayOfWeek);
};
